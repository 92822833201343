import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./payments_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import { user_name_filter_text, address_colony_filter_text, address_municipality_filter_text, address_privated_filter_text, address_title_text, address_type_filter_text, dropdown_default_text, filters_text, address_state_filter_text, payment_title_text } from '../../constant';
import Breadcrumb from '../../components/common/breadcrumb';
import {paymentStatuses} from '../../constant/constants';
import { Link } from 'react-router-dom';


const PaymentsView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, find, totalElements} = useCrud({url: "payment", setLoading: setLoading, firstLoad:false})
    const [selectedClient, setSelectedClient] = useState('')
    const [selectedOrder, setSelectedOrder] = useState('')
    const [selectedCode, setSelectedCode] = useState('')

    const [filters, setFilters] = useState({});
   


    
    

    useEffect(()=>{
        find(filters)
    },[filters])

   
    useEffect(()=>{
        let filtersAux = {}

        if(selectedCode!='')
            filtersAux = {
                    ...filtersAux,
                    code:selectedCode
                }

        if(selectedOrder!='')
            filtersAux = {
                    ...filtersAux,
                    order: {
                        id:selectedOrder
                    }
                }

        if(selectedClient!='')
            filtersAux = {
                    ...filtersAux,
                    order: {
                        ...filtersAux.order,
                        user:{
                            name:selectedClient
                        }
                    }
                }
        
        
        setFilters(filtersAux)
    },[selectedClient,selectedOrder,selectedCode])

    const columns = [
      {name: 'Folio',minWidth: '200px',selector: (row) =>row.code,sortable: true,center:true,},
      {name: 'Monto',selector: (row) =>row.amount,sortable: true,center:true,},
      {name: 'Orden', minWidth: '170px',selector: (row) =>row.orderLink,sortable: true,center:true,},
      {name: 'Cliente',minWidth: '170px',selector: (row) =>row.order.user.name,sortable: true,center:true,},
      {name: 'Estado',minWidth: '170px',selector: (row) =>row.status,sortable: true,center:true,},
      {name: 'Tipo',minWidth: '170px',selector: (row) => row.type.name,sortable: true,center:true,}
  ];


    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };

    return (
        <div>
            <Breadcrumb title={payment_title_text} />
            <br/>
            <div className="row">
                <div className="col">
                    <label>{'Folio'}</label>
                    <input className="form-control" type="text" autocomplete="off" style={{marginLeft: 10}} value={selectedCode} onChange={(evt)=>{setSelectedCode(evt.target.value)}} />
                </div>
                <div className="col">
                    <label>{'Cliente'}</label>
                    <input className="form-control" type="text" autocomplete="off" style={{marginLeft: 10}} value={selectedClient} onChange={(evt)=>{setSelectedClient(evt.target.value)}} />
                </div>
                <div className="col">
                    <label>{'Orden'}</label>
                    <input className="form-control" type="number" autocomplete="off" style={{marginLeft: 10}} value={selectedOrder} onChange={(evt)=>{
                        if(parseInt(evt.target.value) != 'NaN')
                            setSelectedOrder(parseInt(evt.target.value))
                        if(evt.target.value == '')
                            setSelectedOrder(evt.target.value)
                        }} />
                </div>
            </div>
            <br/> 
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list.map(row => {
                    return {
                        ...row,
                        status: paymentStatuses[row.status],
                        orderLink:<Link style={{color:"black"}} to={`/orders/details?id=${row.order.id}`}>{row.order.id}</Link>
                    }
                })}
                />
            
            
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
        </div>
      
      )
}

export default PaymentsView;