import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./service_request_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import { service_title_text, order_statuses_dropdown_texts, address_colony_filter_text, address_municipality_filter_text, address_privated_filter_text, address_title_text, address_type_filter_text, dropdown_default_text, filters_text, service_request_invoice_required_filter_text, service_request_service_filter_text, service_request_status_filter_text, service_request_title_text } from '../../constant';
import Breadcrumb from '../../components/common/breadcrumb';
import { serviceRequestStatuses, serviceRequestStatusesColors, serviceRequestStatusesIcons } from '../../constant/constants';
import Dropdown from '../../components/my-components/dropdown';
import { Grid } from 'react-feather';

const ServiceRequestView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, find, totalElements, reload} = useCrud({url: "servicerequest", setLoading: setLoading, firstLoad:false})
    const {list: services} = useCrud({url:"services",  setLoading: setLoading, firstLoad: true})
    const [selectedService, setSelectedService] = useState({
        id:-1,
        name: "Service"
    });
    const [selectedStatus, setSelectedStatus] = useState(["PENDING"]);
    const [selectedInvoiceRequired, setSelectedInvoiceRequired] = useState(undefined);
    const [filters, setFilters] = useState({
        'status $in': selectedStatus
    });
    const [statusCount, setStatusCount] = useState({
        "ALL":0,
        "PENDING":0,
        "DONE":0,
        "CANCELLED":0
    })


    const countRequests = async () => {
            
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}servicerequest/find/count-all-by-status`,
        {
            method:"GET",
            headers:{
            "Content-Type":"application/json",
            "Authorization":`Bearer ${localStorage.getItem("userToken")}`
            },
            credentials:"include"
            }
        )
        if(response.ok){
            const counts =  await response.json()
            let total = 0
            Object.keys(counts).map(status => {
                total += counts[status]
            })

            setStatusCount({
                'ALL': total,
                ...counts
            })
        }
            
            
    }

    
    

    useEffect(()=>{
        find(filters)
    },[filters])

   
    useEffect(()=>{
        let filtersAux = {}

        if(selectedService.id!=-1)
            filtersAux = {
                ...filtersAux,
                service:{id:parseInt(selectedService.id)}
            }

        if(selectedStatus.length > 0)
            filtersAux= {
                    ...filtersAux,
                    'status $in':selectedStatus
            }

        if(selectedInvoiceRequired!=undefined)
            filtersAux = {
                ...filtersAux,
                invoiceRequired: selectedInvoiceRequired
            }
        
        setFilters(filtersAux)
    },[selectedService, selectedStatus, selectedInvoiceRequired])

    const columns = [
      {name: 'Id de Solicitud',minWidth: '200px',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Requiere Factura',selector: (row) =>row.invoiceRequired?"Sí":"No",sortable: true,center:true,},
      {name: 'Costo',minWidth: '200px',selector: (row) =>row.prize,sortable: true,center:true,},
      {name: 'Servicio',minWidth: '200px',selector: (row) =>row.service.name,sortable: true,center:true,},
      {name: 'Estado',minWidth: '200px',selector: (row) => row.status,sortable: true,center:true,},
      {name: 'Usuario',minWidth: '200px',selector: (row) =>row.user.email,sortable: true,center:true,},
    ];


    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };
    
    const onChangeStatus = async (row, newStatus) => {

    
        setLoading(true)
    
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}servicerequest/${row.id}/change-status/${newStatus}`,
                {
                method:"PUT",
                headers:{
                    "Content-Type":"application/json",
                    "Authorization":`Bearer ${localStorage.getItem("userToken")}`
                }
                }
            )
                
        setLoading(false)
    
        if(response.ok){
            await reload()
            return
        }

        toast.error("Upps, there are some errors. Please, try again")
        
    
    }

    useEffect(()=>{
        if(filters['status $in'] && filters['status $in'].length === 1 && filters['status $in'][0] === "PENDING")
            find(filters,null,[{column:'id',order:'ASC'}])
        else
            find(filters,null,[{column:'id',order:'DESC'}])

        countRequests()
    },[filters])

    return (
        <div>
            <Breadcrumb parent={service_title_text} title={service_request_title_text}/>
            <br/>
            <div className="row">
                <div className="col">
                    <label>{service_request_service_filter_text}</label>
                    <select className="form-control digits" id="exampleFormControlSelect23" onChange={(evt)=>{setSelectedService({id: evt.target.value})}}>
                        <option value={-1}>{dropdown_default_text}</option>
                        {
                           services.map(service => <option value={service.id}>{service.name}</option>)
                        }
                    </select>
                </div>
                <div className="col">
                    <label>{service_request_invoice_required_filter_text}</label>
                    <select className="form-control digits"  onChange={(evt)=>{setSelectedInvoiceRequired(evt.target.value=="true"?true:evt.target.value=="false"?false:undefined)}}>
                        <option value={-1}>{"Ninguno"}</option>
                        <option value={true}>{"Sí"}</option>
                        <option value={false}>{"No"}</option>           
                    </select>
                </div>
            </div>
            <div style={{width:30+'vw'}}>
            </div>
            <br/>
            <div className='status-filter'>
                {   <div className={`status-filter-button ${selectedStatus.length == 0? 'status-filter-button-checked' : ''}`} style={{backgroundColor: serviceRequestStatusesColors['ALL']}} onClick={() => {setSelectedStatus([])}}>
                        <Grid size={30}/>
                        <div className='filter-button-label'>
                            <h6 style={{fontSize:'16px'}}>{order_statuses_dropdown_texts['ALL']}</h6>
                            <h6><b>{statusCount['ALL']}</b></h6>
                        </div>
                    </div>
                }
                {
                    Object.keys(serviceRequestStatuses).map( status => {
                            const checked = selectedStatus.filter(element => element == status).length > 0 
                            return <div className={`status-filter-button ${checked ? 'status-filter-button-checked' : ''}`} style={{backgroundColor: serviceRequestStatusesColors[status]}} onClick={checked ? () => {setSelectedStatus(selectedStatus.filter(element => element != status))} :() => {setSelectedStatus([...selectedStatus,status])}}>
                                {serviceRequestStatusesIcons[status]}
                                <div className='filter-button-label'>
                                    <h6 style={{fontSize:'16px'}}>{serviceRequestStatuses[status]}</h6>
                                    <h6><b>{statusCount[status]}</b></h6>
                                </div>
                            </div>
                            }
                    )
                }
            </div>
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list.map(row => {
                    return {
                        ...row,
                        status: <Dropdown 
                                    classname='status-clip' 
                                    style={{backgroundColor: serviceRequestStatusesColors[row.status]}} 
                                    optionClassname={'status-clip'}
                                    menuStyle={{width:'15vw'}}
                                    value={{name:serviceRequestStatuses[row.status]}} 
                                    onChange={(option)=>{onChangeStatus(row,option.value)}}
                                    options={
                                        Object.keys(serviceRequestStatuses)
                                        .filter(status => status != row.status)
                                        .map(status => { 
                                            return {
                                                value:status, 
                                                name: serviceRequestStatuses[status],
                                                style: {backgroundColor: serviceRequestStatusesColors[status], marginTop:'3px'}
                                                }
                                            })} 
                                    /> 
                                    }
                    })}
                    />
            
            
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
        </div>
      
      )
}

export default ServiceRequestView;