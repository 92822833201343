import { CheckCircle, CheckSquare, Clock, Truck, UserCheck, XCircle } from "react-feather"

export const permissions = [ 
    "ADMIN",
    "OPERATOR",
    "DRIVER",
    "CLIENT"
]

export const orderStatuses = [
    "PENDING",
    "ASSIGNED",
    "ACCEPTED",
    "ONCOURSE",
    "COMPLETED",
    "CANCELLED"
]

export const orderStatusesIcons = {
    "PENDING": <Clock size={30}/>,
    "ASSIGNED": <UserCheck size={30}/>,
    "ACCEPTED": <CheckCircle size={30}/>,
    "ONCOURSE": <Truck size={30}/>,
    "COMPLETED": <CheckSquare size={30}/>,
    "CANCELLED": <XCircle size={30}/>
}

export const orderStatusesColor = {
    "ALL": "#8A2BE2",
    "PENDING": "#FFD700",
    "ASSIGNED": "#00BFFF",
    "ACCEPTED": "#00FFFF",
    "ONCOURSE": "#FFA500",
    "COMPLETED": "#228B22",
    "CANCELLED": "#FF4500"
}

export const serviceRequestStatuses = {
    "PENDING":"Pendiente",
    "DONE":"Completada",
    "CANCELLED":"Cancelada"
}

export const serviceRequestStatusesIcons = {
    "PENDING": <Clock size={30}/>,
    "DONE": <CheckSquare size={30}/>,
    "CANCELLED": <XCircle size={30}/>
}

export const serviceRequestStatusesColors = {
    "ALL": "#8A2BE2",
    "PENDING":"#FFD700",
    "DONE":"#228B22",
    "CANCELLED":"#FF4500"
}

export const userTypes = [
    "ENTERPRISE",
    "CLIENT",
    "DRIVER",
    "ADMIN",
    "OFFICE"
]

export const userTypesToCreate = [
    "DRIVER",
    "ADMIN",
    "OFFICE"
]

export const paymentStatuses = {
    "PENDING":"Pendiente",
    "DONE":"Completado",
    "CANCELLED":"Cancelado"
}