import { useEffect, useRef, useState } from 'react';
import './dropdown.css';

const Dropdown = ({classname, style = {}, menuClassname, menuStyle = {}, optionClassname, options, value, onChange = () => {}}) => {

    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null);
    const [lastPosition, setLastPosition] = useState({ top: 0, right: 0 });

    const checkPosition = () => {
        if (dropdownRef.current) {
            const rect = dropdownRef.current.getBoundingClientRect();
            const currentPosition = { top: rect.top, left: rect.left };

            if (
                currentPosition.top !== lastPosition.top ||
                currentPosition.left !== lastPosition.left
            ) {
                setIsOpen(false);
            }
        }
    };

    useEffect(() => {
        checkPosition();

        let intervalId
        if(isOpen)
             intervalId = setInterval(checkPosition, 100);

        return () => {
            if(intervalId)
                clearInterval(intervalId);
        };
    }, [lastPosition, isOpen]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        
    }, []);


    const handleClick = () => {

        const rect = dropdownRef.current.getBoundingClientRect();
        const currentPosition = { top: rect.top, left: rect.left, bottom: rect.bottom };
        setLastPosition(currentPosition);

        setIsOpen(!isOpen);
    }


    return (<div className='x-dropdown-container' ref={dropdownRef}>
                <div className={classname ? classname : 'x-dropdown'} style={style} onClick={handleClick}>{value.name}</div>
                { isOpen &&
                    <div className={menuClassname? 'x-dropdown-menu ' + menuClassname : 'x-dropdown-menu x-dropdown'} style={{...menuStyle,top:lastPosition.bottom, left:lastPosition.left}}>
                    {
                        options.map( option => <div className={optionClassname ? optionClassname : 'x-dropdown-option'} style={option.style ? option.style : {}} onClick={()=>{onChange(option); setIsOpen(false)}}>{option.name}</div>)
                    }
                    </div>
                }
            </div>
    );
}

export default Dropdown;