import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component'
import useCrud from '../../hooks/use_crud';
import "react-toastify/dist/ReactToastify.css";
import "./orders_view.css";
import LoadingSpinner from '../../components/my-components/loading_spinner';
import IconButton from '../../components/my-components/my_icon_button';
import {order_breadcrumb_title_text, delete_modal_text, delete_text, dropdown_default_text, filters_text, order_assign_button_text, order_state_filter_text, order_statuses_dropdown_texts, order_title_text, order_type_filter_text, role_classname_text, view_details_text } from '../../constant';
import { orderStatuses, orderStatusesColor, orderStatusesIcons } from '../../constant/constants';
import AssignDriverModal from './asign_driver_modal';
import Breadcrumb from '../../components/common/breadcrumb';
import ConfirmationModal from '../../components/my-components/confirmation-modal';
import { formatNumberWithCurrency } from '../../utils/formatters';
import { Link } from 'react-router-dom';
import { Grid, Home } from 'react-feather';



const OrdersView = () =>{

    const [loading,setLoading] = useState(false)
    const {list, find,Delete, totalElements, reload} = useCrud({url: "orders", setLoading: setLoading, firstLoad:false})
    const {list: orderTypes} = useCrud({url: "ordertypes", setLoading: setLoading})
    const [statusCount, setStatusCount] = useState({
        "ALL":0,
        "PENDING":0,
        "ASSIGNED":0,
        "ACCEPTED":0,
        "ONCOURSE":0,
        "COMPLETED":0,
        "CANCELLED":0
    })
    const [assignDriverModalIsOpen,setAssignDriverModalIsOpen] = useState(false);
    const [selectedOrder,setSelectedOrder] = useState({});

    const [selectedOrderType, setSelectedOrderType] = useState({
        id:-1,
        name: "Tipo"
    });

    const [selectedOrderStatus, setSelectedOrderStatus] = useState(["PENDING"]);

    const [filters, setFilters] = useState({
        'status $in': selectedOrderStatus
    });
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [confirmationModalData, setConfirmationModalData] = useState({
        description:'',
        onSubmit:()=>{},
        onCancel:()=>setIsConfirmationModalOpen(false)
    })


    const countOrders = async () => {
            
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}orders/find/count-all-by-status`,
        {
            method:"GET",
            headers:{
            "Content-Type":"application/json",
            "Authorization":`Bearer ${localStorage.getItem("userToken")}`
            },
            credentials:"include"
            }
        )
        if(response.ok){
            const counts =  await response.json()
            let total = 0
            Object.keys(counts).map(status => {
                total += counts[status]
            })

            setStatusCount({
                'ALL': total,
                ...counts
            })
        }
            
            
    }


    useEffect(()=>{
        if(filters['status $in'] && filters['status $in'].length === 1 && filters['status $in'][0] === "PENDING")
            find(filters,null,[{column:'id',order:'ASC'}])
        else
            find(filters,null,[{column:'id',order:'DESC'}])

        countOrders()
    },[filters])
    
   
    useEffect(()=>{
        let filtersAux = {}
       
        if(selectedOrderType.id!=-1)
            filtersAux= {
                    ...filtersAux,
                    orderType:{id:selectedOrderType.id}
            }

        if(selectedOrderStatus.length > 0)
                filtersAux= {
                        ...filtersAux,
                        'status $in':selectedOrderStatus
                }

        setFilters(filtersAux)
    },[selectedOrderType,selectedOrderStatus])

   
    

    const columns = [
      {name: 'Id de Orden',minWidth: '200px',selector: (row) =>row.id,sortable: true,center:true,},
      {name: 'Tipo',selector: (row) =>row.orderType.name,sortable: true,center:true,},
      {name: 'Cliente',minWidth: '200px',selector: (row) =>row.user.name,sortable: true,center:true,},
      {name: 'Estado',selector: (row) => row.status,sortable: true,center:true,},
      {name: 'Precio',selector: (row) =>formatNumberWithCurrency(row.prize),sortable: true,center:true,},
      {name: 'Requiere Factura',selector: (row) =>row.invoiceRequired?"Sí":"No",minWidth: '200px',sortable: true,center:true,},
      {name: 'Acciones',selector: (row) =>row.actions,sortable: false,center:true},

  ];

    const handleDelete = (id) => {
        setIsConfirmationModalOpen(true)
        setConfirmationModalData({
            ...confirmationModalData,
            description: `${delete_modal_text}${role_classname_text}?`,
            onSubmit: async ()=>{
                await Delete(id)
                setIsConfirmationModalOpen(false)
            }
        })
    
    };

    const handlePageChange = page => {
        find(filters,{size:10,page:page-1});
      };


    const handlePerRowsChange = async (newPerPage, page) => {
        find(filters,{size:newPerPage,page:page});

      };
    
    const onAssign = () => {
        setAssignDriverModalIsOpen(false)
        reload()
    }

    return (
        <div>
            <Breadcrumb parent={order_title_text} title={order_breadcrumb_title_text}/>
            <br/>
            <div className="row">
                <div className="col">
                    <label>{order_type_filter_text}</label>
                    <select className="form-control digits" id="exampleFormControlSelect23"  onChange={(evt)=>{setSelectedOrderType({id: parseInt(evt.target.value)})}}>
                        <option value={-1}>{dropdown_default_text}</option>
                        {
                           orderTypes.map(orderType => <option value={orderType.id}>{orderType.name}</option>)
                        }
                    </select>
                </div>
            </div>
            <br/>
            <div className='status-filter'>
                {   <div className={`status-filter-button ${selectedOrderStatus.length == 0? 'status-filter-button-checked' : ''}`} style={{backgroundColor: orderStatusesColor['ALL']}} onClick={() => {setSelectedOrderStatus([])}}>
                        <Grid size={30}/>
                        <div className='filter-button-label'>
                            <h6 style={{fontSize:'16px'}}>{order_statuses_dropdown_texts['ALL']}</h6>
                            <h6><b>{statusCount['ALL']}</b></h6>
                        </div>
                    </div>
                }
                {
                    orderStatuses.map( status => {
                            const checked = selectedOrderStatus.filter(element => element == status).length > 0 
                            return <div className={`status-filter-button ${checked ? 'status-filter-button-checked' : ''}`} style={{backgroundColor: orderStatusesColor[status]}} onClick={checked ? () => {setSelectedOrderStatus(selectedOrderStatus.filter(element => element != status))} :() => {setSelectedOrderStatus([...selectedOrderStatus,status])}}>
                                {orderStatusesIcons[status]}
                                <div className='filter-button-label'>
                                    <h6 style={{fontSize:'16px'}}>{order_statuses_dropdown_texts[status]}</h6>
                                    <h6><b>{statusCount[status]}</b></h6>
                                </div>
                            </div>
                            }
                    )
                }
            </div>
            <DataTable
                pagination 
                paginationServer
                paginationComponentOptions ={{
                    noRowsPerPage:true
                }}
                paginationTotalRows={totalElements} 
                onChangeRowsPerPage={handlePerRowsChange} 
                onChangePage={handlePageChange}
                striped={true}
                center={true}
                columns={columns}
                data={list.map(row => {
                    return {
                      ...row,
                      status: <div className='status-clip' style={{backgroundColor: orderStatusesColor[row.status]}}>{order_statuses_dropdown_texts[row.status]}</div>,
                      actions: <div className='flex'>
                                    <Link to={`/orders/details?id=${row.id}`} ><IconButton icon={
                                        <span className='action-box large update-btn'>
                                            <i className='icon'>
                                                <i className='icon-eye'></i>
                                            </i>
                                        </span>
                                        }
                                        text={view_details_text}
                                        onClick={()=>{}}
                                        color="info"
                                        />
                                    </Link>
                                    {
                                        row.status=="PENDING" && 
                                        <IconButton icon={
                                            <span className='action-box large delete-btn' title='Delete Task'>
                                                <i className='icon'>
                                                    <i className='icon-truck'></i>
                                                </i>
                                            </span>
                                            }
                                            text={order_assign_button_text}
                                            onClick={()=>{
                                                setSelectedOrder(row);
                                                setAssignDriverModalIsOpen(true);
                                            }}
                                            color="primary"
                                        />
                                    }
                                    <IconButton icon={
                                        <span className='action-box large delete-btn' title='Delete Task'>
                                            <i className='icon'>
                                                <i className='icon-trash'></i>
                                            </i>
                                        </span>
                                        }
                                        text={delete_text}
                                        onClick={()=>{handleDelete(row.id)}}
                                        color="danger"
                                    />
                                </div>
                    }
                  })}
                />
            <AssignDriverModal
                isVisible={assignDriverModalIsOpen}
                oldRow={selectedOrder}
                onAssign={onAssign}
                onCancel={()=>{setAssignDriverModalIsOpen(false)}}
            />
            <LoadingSpinner isVisible={loading} />
            <ToastContainer />
            <ConfirmationModal 
                isVisible={isConfirmationModalOpen} 
                description={confirmationModalData.description} 
                onSubmit={confirmationModalData.onSubmit} 
                onCancel={confirmationModalData.onCancel}
                />
        </div>
      
      )
}

export default OrdersView;